import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const VideoIconPlay = ({ className, color }) => {
  const stroke = getIconColor(color)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<path d="M3.5 4.2684C3.5 3.69795 4.1116 3.33633 4.61144 3.61124L12.3052 7.84279C12.8232 8.12773 12.8232 8.87217 12.3052 9.15711L4.61144 13.3887C4.1116 13.6636 3.5 13.3019 3.5 12.7315V4.2684Z" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"/>
</svg>    
  )
}

export default VideoIconPlay
