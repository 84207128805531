import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./PropertyCardsidebar.scss"
import CTALink from "@components/elements/CTALink"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"

const PropertyCardsidebar = ({pType}) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSideBar {
          nodes {
            add_cta_tile {
            type
            cta{
              ...ctaFragment
            
            }
              title
              description {
                data {
                  description
                }
              }
              image {
                url
              }
            
            
          }
              strapi_id
             ggfx_results {
            ...STRAPI_GGFX_RESULTFragment
          }
        }
      }
    }
  `)


  return (
    data?.allStrapiSideBar?.nodes[0]?.add_cta_tile.map((item, i) => {
      return (
        pType === item.type &&
        <div className={item?.image?.url ? "card-view property" : "card-view property no-image"}>
          {item?.image?.url &&
          <GGFXImage
          ImageSrc={item?.image}
          altText={item?.title}
          imagetransforms={data?.allStrapiSideBar?.nodes[0]?.ggfx_results}
          renderer="srcSet"
          imagename={"side-bar.add_cta_tile.image"}
          strapiID={data?.allStrapiSideBar?.nodes[0]?.strapi_id}
        />}
          <div className="content">
            <h4>{item?.title}</h4>
            <div className="description">{parseHTML(item?.description?.data?.description)}</div>
            <CTALink cta={item?.cta} className="button button-orange" />
          </div>
        </div>
      )
    })

  )
}

export default PropertyCardsidebar